import React from 'react'
import { Text } from '@/app/atoms/text/text'
import { Link, PageProps } from 'gatsby'
import { Button } from '@/app/atoms/button/button'
import { css } from '@emotion/core'
import { SEO } from '@/app/organisms/layout/components/seo'

const ThankYou: React.FC<PageProps> = () => (
  <>
    <SEO pageTitle="Thank You" />
    <main
      css={css`
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      `}
    >
      <Text type="subTitle" alignment="center">
        Thank you for contacting us
      </Text>
      <div css={{ height: '8px' }} />
      <Text type="body" alignment="center">
        We will be in touch shortly
      </Text>
      <Link
        to="/"
        css={css`
          padding: 48px;
        `}
      >
        <Button type="button" size="medium">
          Return to Home
        </Button>
      </Link>
    </main>
  </>
)

export default ThankYou
