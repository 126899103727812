import React, { FC } from 'react'
import { motion } from 'framer-motion'
import styles from './styles'
import { useThemeHook } from '@/hooks/useThemeHook'

export type ButtonSize = 'xSmall' | 'small' | 'medium' | 'large'
export type ButtonStyle = 'primary' | 'success' | 'danger' | 'warning'
type ButtonProps = {
  size: ButtonSize
  style?: ButtonStyle
  outlined?: boolean
  onEvent?: (e: React.SyntheticEvent<Element, Event>) => void
  onClick?: () => void
  type?: 'submit' | 'button'
  isDisabled?: boolean
}
export const Button: FC<ButtonProps> = ({
  children,
  size,
  style = 'primary',
  outlined,
  onClick,
  type = 'button',
  isDisabled,
}) => {
  const theme = useThemeHook()
  return (
    <motion.button
      type={type}
      css={styles.button(size, style, theme, outlined, isDisabled)}
      onClick={() => onClick?.()}
      disabled={isDisabled}
    >
      {children}
    </motion.button>
  )
}
