import { ThemeTypes } from '@/app/theme/theme'
import { css } from '@emotion/core'
import { ButtonSize, ButtonStyle } from './button'

export default {
  button: (
    size: ButtonSize,
    style: ButtonStyle,
    theme: ThemeTypes,
    outlined?: boolean,
    isDisabled?: boolean,
  ) => css`
    cursor: pointer;
    padding: ${size === 'large'
      ? '16px 32px'
      : size === 'medium'
      ? '12px 24px'
      : size === 'small'
      ? '8px 16px'
      : '4px 8px'};
    border: ${outlined ? '2px solid' : '2px transparent'};
    font-size: ${size === 'large'
      ? '16px'
      : size === 'medium'
      ? '14px'
      : size === 'small'
      ? '12px'
      : '10px'};
    border-radius: ${theme.button.borderRadius};
    background-color: ${theme.button[style]};
    color: ${theme.button.fontColor};
    box-shadow: ${theme.button.shadow};
    opacity: ${isDisabled ? 0.6 : 1};
  `,
}
